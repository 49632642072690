import React, { useEffect } from "react";
import PageLayout from "../PageLayout";
import PageContent from "../PageContent";
import PageBoundary from "../PageBoundary";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  LegalDocumentAttributes,
} from "../../types/cms";
import ReactMarkdown from "react-markdown";
import { useAuthContext } from "../../contexts/authContext";
import useNorthstar from "../../hooks/northstarHook";
import LoadingStatus from "../LoadingStatus";

interface LegalDocumentProps {
  documentType: "privacy-policy" | "disclaimers" | "uk-regulatory-disclosures";
}

const LegalDocument: React.FC<LegalDocumentProps> = ({ documentType }) => {
  const { accessToken, clearIdentity } = useAuthContext();

  const { data, isLoading, error } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<LegalDocumentAttributes>>
  >(
    `/api/legal-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      filters: {
        document_id: {
          $eq: documentType,
        },
      },
    },
  );

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  let seoData;

  switch (documentType) {
    case "privacy-policy":
      seoData = {
        title: `BAM Applied Insights | Privacy Policy`,
        description: "BAM Applied Insights Privacy Policy",
      };
      break;
    case "disclaimers":
      seoData = {
        title: `BAM Applied Insights | Disclaimers & BAM User Agreement`,
        description: "BAM Applied Insights Disclaimers & BAM User Agreement",
      };
      break;
    case "uk-regulatory-disclosures":
      seoData = {
        title: "BAM Applied Insights | U.K. Regulatory Disclosures",
        description: "BAM Applied Insights U.K. Regulatory Disclosures",
      };
      break;
  }

  let documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
  if (error) {
    documentTemplate = <LoadingStatus loadingType="legal_document_error" />;
  } else {
    if (isLoading) {
      documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
    } else if (!data?.data.length) {
      documentTemplate = <LoadingStatus loadingType="legal_document_loading" />;
    } else {
      const document = data.data[0].attributes;
      documentTemplate = (
        <div
          className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-4 bg-white border-gray-200/90 border-t-bam-alternative-blue shadow-lg"
        >
          <div className="my-3 pt-3 pb-2">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
              {document?.title}
            </h1>
          </div>
          <div className="mt-4 mb-6">
            <div className="prose prose-a:text-bam-orange w-full max-w-none">
              <div className="leading-relaxed">
                <ReactMarkdown>{document?.text}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <PageLayout seo={seoData}>
      <PageContent>
        <PageBoundary>{documentTemplate}</PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default LegalDocument;
